import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { TaasUserDetails } from 'utimaco-common-ui-angular';
import { TaasJwtTokenDetails } from '../models/taas-jwt-token-details';

@Injectable({
  providedIn: 'root'
})
export class TaasUserService {

  constructor(private keycloakService: KeycloakService) { }

  performLogout() {
    this.keycloakService?.logout();
  }

  async getCurrentUserDetails(): Promise<TaasUserDetails> {
    const tokenString = await this.keycloakService.getToken();
    const tokenObject: TaasJwtTokenDetails = JSON.parse(atob(tokenString.split('.')[1]));
    const userDetails: TaasUserDetails = {
      givenName: tokenObject.given_name,
      familyName: tokenObject.family_name,
      email: tokenObject.email,
      initials: tokenObject.given_name.trim().substring(0, 1) + tokenObject.family_name.trim().substring(0, 1),
      roles: tokenObject.realm_access.roles
    }
    return userDetails;
  }
}
