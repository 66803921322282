{
  "name": "taas-portal-frontend",
  "version": "0.1.6",
  "scripts": {
    "ng": "ng",
    "lint": "ng lint",
    "test": "ng test",
    "start": "ng serve",
    "start-prod": "ng serve --configuration production",
    "start-preprod": "ng serve --configuration preprod",
    "start-de": "ng serve --configuration=de",
    "start-en": "ng serve --configuration=en",
    "start-sv": "ng serve --configuration=sv",
    "translate": "ng extract-i18n",
    "build": "ng build",
    "build:stats": "ng build --stats-json && esbuild-visualizer --metadata ./dist/taas-portal-frontend/stats.json --filename ./dist/taas-portal-frontend/stats.html && open-cli ./dist/taas-portal-frontend/stats.html",
    "build-prod": "ng build --configuration production",
    "build-preprod": "ng build --configuration preprod",
    "build-dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@material-icons/svg": "1.0.33",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^16.1.0",
    "keycloak-js": "^26.1.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.8.1",
    "utimaco-common-ui-angular": "https://repo.fra1.us.utimaco.cloud/repository/npm-hosted/utimaco-common-ui-angular/-/utimaco-common-ui-angular-0.17.1.tgz",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.15",
    "@angular-eslint/builder": "^18.4.3",
    "@angular-eslint/eslint-plugin": "^18.4.3",
    "@angular-eslint/eslint-plugin-template": "^18.4.3",
    "@angular-eslint/schematics": "^18.4.3",
    "@angular-eslint/template-parser": "^18.4.3",
    "@angular/cli": "^18.2.14",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "8.22.0",
    "@typescript-eslint/parser": "8.22.0",
    "esbuild-visualizer": "^0.7.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-extract-i18n-merge": "^2.12.0",
    "open-cli": "^8.0.0",
    "typescript": "~5.4.5"
  }
}
