import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { TaasUserService } from "@utimaco-core/services/taas-user-service.service";
export function roleGuard(requiredRoles: string[], redirectUrl: string): CanActivateFn {
    return async () => {
        const taasUserService: TaasUserService = inject(TaasUserService);
        const router: Router = inject(Router);
        let currentUserRoles: string[] = [];
        let requiredRolePresent = false;
        try {
            const userDetails = await taasUserService.getCurrentUserDetails();
            currentUserRoles = userDetails.roles!;
            requiredRoles.forEach(role => {
                if (currentUserRoles.includes(role)) {
                    requiredRolePresent = true;
                }
            });
            
            return requiredRolePresent || router.createUrlTree([redirectUrl]);
        } catch (error) {
            console.error('Error checking user roles:', error);
            return false;
        }
    };
}