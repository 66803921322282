import { TaasMenuItem } from "utimaco-common-ui-angular";

// see https://netbasal.com/simplifying-file-imports-in-angular-with-new-loader-options-4e94a0eb18af for importing svgs in angular ^17.1
import appsIcon from '@material-icons/svg/svg/apps/baseline.svg';
import cartIcon from '@material-icons/svg/svg/shopping_cart/baseline.svg';
import groupIcon from '@material-icons/svg/svg/group/baseline.svg';
import descriptionIcon from '@material-icons/svg/svg/description/baseline.svg';
import contentCopyIcon from '@material-icons/svg/svg/content_copy/baseline.svg';
import mailLockIcon from '@material-icons/svg/svg/mail_lock/baseline.svg';
import helpIcon from '@material-icons/svg/svg/help/baseline.svg';
import quizIcon from '@material-icons/svg/svg/quiz/baseline.svg';
import receiptLongIcon from '@material-icons/svg/svg/receipt_long/baseline.svg';
import accountBalanceIcon from '@material-icons/svg/svg/account_balance/baseline.svg';
import supportAgentIcon from '@material-icons/svg/svg/support_agent/baseline.svg';

//TODO: get active services dynamically from REST API!
export const TAAS_MENU_ITEM_LIST_SERVICEMANAGER: TaasMenuItem[] = [
    { id: 0, type: "link", active: true, name: $localize`Services & Products`, link: "/", iconSvg: appsIcon, children: [] },
    { id: 1, type: "link", active: true, name: $localize`Marketplace`, link: "https://marketplace.dev.services.utimaco.com", iconSvg: cartIcon, children: [] },
    { id: 2, type: "link", active: false, name: $localize`User Management`, link: "/users", iconSvg: groupIcon, children: [] },
    {
        id: 3, type: "section", active: false, name: $localize`Documents & Downloads`, link: "/docs", iconSvg: descriptionIcon, children: [
            { id: 4, type: "link", active: false, name: $localize`General`, link: "/docs/public", iconSvg: contentCopyIcon, children: [] },
            { id: 5, type: "link", active: false, name: $localize`Private`, link: "/docs/private", iconSvg: mailLockIcon, children: [] },
        ]
    },
    {
        id: 6, type: "section", active: false, name: $localize`Support`, link: "/support", iconSvg: helpIcon, children: [
            { id: 7, type: "link", active: false, name: $localize`Knowledgebase`, link: "/support/knowledgebase", iconSvg: quizIcon, children: [] },
            { id: 8, type: "link", active: false, name: $localize`Tickets`, link: "/support/tickets", iconSvg: supportAgentIcon, children: [] },
        ]
    },
    /*
    {
        id: 9, type: "section", active: false, name: $localize`Contracts`, link: "/legal", matIconName: "privacy_tip", children: [
            { id: 10, type: "link", active: false, name: $localize`General`, link: "/legal/public", matIconName: "document_scanner", children: [] },
            { id: 11, type: "link", active: false, name: $localize`Private`, link: "/legal/private", matIconName: "document_scanner", children: [] },
        ]
    },
    */
    {
        id: 12, type: "section", active: false, name: $localize`Billing`, link: "/billing", iconSvg: receiptLongIcon, children: [
            { id: 13, type: "link", active: false, name: $localize`Billing Profiles`, link: "/billing/billingProfiles", iconSvg: accountBalanceIcon, children: [] },
            { id: 14, type: "link", active: false, name: $localize`Invoices`, link: "/billing/invoices", iconSvg: receiptLongIcon, children: [] },
        ]
    }
];